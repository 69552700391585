var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ar-volume" },
    [
      _c("icon-button", {
        staticClass: "ar-volume__icon",
        attrs: { name: "volume" }
      }),
      _vm._v(" "),
      _c("line-control", {
        staticClass: "ar-volume-bar",
        attrs: { "ref-id": "volume", percentage: _vm.volume },
        on: { "change-linehead": _vm.onChangeLinehead }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-060e62ba", { render: render, staticRenderFns: staticRenderFns })
  }
}