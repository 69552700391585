var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("icon-button", {
    staticClass: "ar-icon ar-icon__xs ar-icon--no-border",
    attrs: { name: "save" },
    nativeOn: {
      click: function($event) {
        return _vm.upload($event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5016f904", { render: render, staticRenderFns: staticRenderFns })
  }
}